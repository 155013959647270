from{
  position: relative;
  width: 100%;
  overflow: hidden;
  &.dashboard-form{
    .form-control{
      border-color: #E5E5E5;
    }
  }
}
.form-check-label{
color: #757575;  
font-size: 14px;
}

.include-check{
  label{
    color: #313131;
  }
}
.form-group{
  margin-bottom: 12px; 
}


.form-control{
  font-size: 13px;
  padding: 12px 18px;
  &:focus-visible, &:focus{
    box-shadow: none !important;
    border-color: #0073ba !important;
  }
  &::placeholder{
    color: #AAA;
    
  }
}

.form-check-input{
  &:focus, & :focus-visible{
    box-shadow: none;
    outline: 0;
  }
}
.form-check-input:checked{
  background-color: #0073ba;
}

.custom-select {
  option {
    background-color: yellow; /* Change the background color to your desired color */
  }
}
.react-datepicker-wrapper{
  display: block !important;
  width: 100% !important;
  input{
    width: 100%;
    padding: 12px 18px;
    border: 1px solid #E5E5E5;
    border-radius: 3px 0 0 3px;
    background-color: transparent;
    z-index: 2;
    position: relative;
    &:focus-visible{
      outline: none;
    }
  }
}
.date-range-picker{
  .react-datepicker-wrapper{
    height: 100%;
    .react-datepicker__input-container{
      height: 100%;
      input{
        height: 100%;
        background-color: white;
      }
    }
  }
}
.search-icon-date-selection{
  border: 1px solid #E5E5E5;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}
table{
  width: 100%;
}