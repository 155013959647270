@import "Style/base";

@keyframes image-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loaderComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: $color-white-fade;
  z-index: 9990;
  display: flex;

  
  
}
