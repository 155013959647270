.LogList {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 8px 10px;
    p{
        margin-bottom: 0;
    }
    &:nth-child(odd) {
        border: 1px solid #ccc;
        border-left: none;
        border-right: none;
      background-color: #f1f1f1;
    }
  }
}
