@import "./variable";
.btn {
  padding: 0 25px;
  border-radius: 0;
  height: 45.5px;
  line-height: 45.5px;
  text-decoration: none;
  font-family: $prompt;
  font-weight: 600;
  border-radius: 12px;
  font-size: 12px;

  &.btn-outline-primary{
    background-color: #D9EAF5;
    color: #0073BA;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 500;
    &:hover{
      background-color: transparent;
      color: #0073BA;
      border: 1px solid #0073BA;
    }
  }

  &.btn-primary {
    color: #fff;
    border-color: rgba(255, 160, 0, 0.35);
    background: $blue;
    border-radius: 4px;

    svg{
      font-size: 13px;
      margin-right: 3px;
      margin-bottom: 3px;
    }

    &:hover {
     background-color: transparent;
     border-color: #0073BA;
     color: $blue;
    }
  }

  &.btn-secondary {
    color: #f28e1c;
    border-color: rgba(255, 160, 0, 0.35);
    background: transparent;

    &:hover {
      background: #e58310;
    }
  }
  &.btnIcon {
    color: rgba(38, 50, 56, 1);
    height: 50px;
    line-height: 50px;
    &:hover {
      i,
      img {
        transform: translateX(3px);
        transition: all ease 0.3s;
      }
    }
    i {
      background-color: rgba(255, 255, 255, 0.34);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      line-height: 30px;
      text-align: center;
      margin-left: 10px;
      transform: translateX(0px);
      transition: all ease 0.3s;
    }

    img {
      transform: translateX(0px);
      transition: all ease 0.3s;
      width: 42px;
    }
  }

  &.btn-table{
    padding: 0 12px;
    height: 32px !important;
    line-height: 32px;
    border-radius: 3px;
    color: #0073ba;
    margin: 0 3px;
    border: 1px solid #0073BA;
    &:disabled{
      border-color: gray;
      color: gray;
    }
  }
}


