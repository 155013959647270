@import "../../Style/include-media";
.fromHolder {
  border-radius: 4px;
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  background-image: url(../../Images/loginBg.png);
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
  min-width: 1200px;
  margin: auto;
  padding: 50px 100px;
  @include media("<widescreen-large") {
    min-width: 1150px;
  }
  @include media("<widescreen") {
    min-width: 940px;
  }
  @include media("<tablet-x") {
    background-image: unset;
    min-width: 650px;
    padding: 50px;
  }
  @include media("<tablet-x") {
    background-image: unset;
    min-width: 600px;
    padding: 50px 20px;
    border-radius: 20px;
  }
  @include media("<phone") {
    min-width: 300px;
    padding: 35px 10px;
    h3 {
      font-size: calc(1.3rem + 0.6vw) !important;
      letter-spacing: 0 !important;
    }
  }
  @include media("<xs-phone") {
    padding: 10px 0px;
    h3 {
      display: none;
    }
  }
  .logo {
    width: 250px;
    margin: auto;
    margin-bottom: 18px;
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  h3 {
    letter-spacing: 4px;
    font-weight: 500;
    font-size: 34px;
  }
}
