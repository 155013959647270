@import "~bootstrap/scss/bootstrap";
@import "Style/base";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ebf3fa;
  font-family: $prompt;
  box-sizing: border-box;
  font-size: 13px;
}

b {
  font-weight: 500;
}

.badge {
  font-size: 12px;
  padding: 10px;
  text-decoration: none;

  &.custom-badge {
    position: relative;
    margin-left: 35px;
    .cancel-btn {
      position: absolute;
      background-color: red;
      display: inline-block;
      width: 28px;
      height: 28px;
      padding: 8px;
      left: -40px;
      top: 0;

      &:after,
      &:before {
        position: absolute;
        left: 13px;
        content: " ";
        height: 20px;
        width: 2px;
        background-color: #fff;
        top: 4px;
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:before {
        transform: rotate(45deg);
      }
    }
  }

  &.emergency {
    color: #ef4c3c;
    background-color: #ef4c3c1f;
    border: 1px solid #ef4c3c21;
  }

  &.multiple {
    color: #45b39d;
    background-color: #45b39d17;
    border: 1px solid #45b39d1c;
  }

  &.priority {
    color: #f1948a;
    background-color: #f1948a1a;
    border: 1px solid #f1948a14;
  }

  &.projectWork {
    color: #1b4f72;
    background-color: #1b4f721a;
    border: 1px solid #1b4f7214;
  }

  &.standard {
    color: #3f464a;
    background-color: #69727a17;
    border: 1px solid #62696f29;
  }

  &.default {
    background-color: rgb(246 239 221 / 61%);
    color: rgb(235 170 103 / 98%);
    border: 1px solid rgb(246 220 176 / 26%);
  }
}

h1,
h3,
h2,
h4,
h5,
h6 {
  font-family: $prompt;
  color: $blue;
  font-weight: 500;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-box {
  width: 100%;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

section {
  padding: 35px 20px;
}

footer {
  border-top: 1px solid #e5e5e5;
  padding: 20px 0px 0 20px;
  color: #757575;

  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.link {
  text-decoration: none;
  color: #0073ba;
}

.sidebar {
  width: 260px;
  background-color: #0073ba;
  color: #fff;
  padding: 50px 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  overflow: auto;

  transition: all ease 0.3s;

  .inner-sidebar {
    padding: 0 20px;
  }

  &.collapsed {
    width: 65px;
    transition: all ease 0.3s;
    padding: 50px 0;

    ~ [class*="MainLayout_content"] {
      margin-left: 65px;
      transition: all ease 0.6s;
    }

    [class*="logo"] {
      visibility: hidden;
      opacity: 0;
      transition: all ease 0.6s;
    }

    .inner-sidebar {
      padding: 0;
    }

    .nav {
      font-size: 0;

      .submenu-header {
        text-align: center;

        img {
          margin-right: 0;
        }
      }

      .drop-icon {
        display: none;
      }
    }
  }

  .nav {
    list-style: none;
    padding: 0;
    flex-direction: column;
    visibility: visible;
    transition: all ease 0.6s;

    .nav-item {
      border-radius: 5px;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: #4c9dcf;

        .submenu {
          display: block;
        }
      }

      a {
        color: #fff;
        text-decoration: none;
        display: block;
        padding: 12px;
        font-weight: 600;

        img {
          margin-right: 5px;
          width: 22px;
        }
      }

      .submenu-header {
        padding: 10px;
        display: block;
        font-weight: 600;

        .drop-icon {
          transition: all ease 0.3s;
        }

        img {
          margin-right: 12px;
          width: 22px;
        }

        &:hover &.active {
          .drop-icon {
            transform: rotate(-180deg);
            transition: all ease 0.3s;
          }
        }
      }

      .submenu {
        padding: 0;
        margin: 0;
        list-style: none;
        padding-left: 12px;
        display: none;

        .nav-item {
          padding: 3px;

          &:hover,
          &.active {
            background-color: transparent;

            a {
              color: #87d1ff !important;
            }
          }

          a {
            padding: 0;
          }
        }
      }
    }
  }
}

.card {
  padding: 20px;
  overflow: visible;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.2));
  border: none;
}

[class*="indicatorSeparator"] {
  display: none;
}

.checkboxHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: 100px;

  .form-check {
    flex: 0 0 11.11%;
    margin-right: 8px;
  }
}

.datepicker_box {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    // z-index: 9999;
    width: 42px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9eaf5;
    color: $blue;
    font-size: 18px;
    border-radius: 0px 3px 3px 0px;
    position: absolute;
    right: 0;
    z-index: 0;
    cursor: pointer;
    top: 0.5px;
    padding: 12px;
  }
}

.column {
  flex: 3 1;

  @include media("<widescreen") {
    flex: 0 0 50%;
  }

  @include media("<tablet") {
    flex: 0 0 100%;
  }
}

.headingBox {
  margin-bottom: 12px;

  h6 {
    color: #313131;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
  }
}

.box {
  [class*="col"] {
    border: 1px solid #e5e5e5;
    padding: 12px;

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 0;
    }

    h6 {
      color: #313131;
      font-weight: 500;
      font-size: 13px;
      margin: 0;
    }
  }
}

.dispatchBox {
  border: 1px solid #e5e5e5;
  height: 300px;
  overflow-y: scroll;

  p {
    margin: 10px;
  }
}

[class*="MuiTablePagination-selectLabel"],
[class*="MuiInputBase-root-MuiTablePagination-select"],
[class*="MuiTablePagination-spacer"],
[class="MuiTablePagination-actions"] {
  display: none !important;
}

[class*="MuiTablePagination-displayedRows"] {
  margin-bottom: 0 !important;
}

[class*="MuiTablePagination-toolbar"] {
  justify-content: space-between;
  display: inline-block !important;
  width: 300px;
  float: left;
}

[class*="MuiTablePagination-displayedRows"] {
  margin-top: 20px !important;
}

[class*="MuiPaper-root"] {
  box-shadow: none !important;
  border: 1px solid #e5e5e5;
}

.custom-pagination-controls {
  float: right;
  margin: 0px 20px;
}

table {
  &.modal-table {
    th {
      color: #0073ba;
      width: auto !important;
    }
  }

  &.checkboxTable {
    thead {
      th {
        text-align: center !important;

        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          width: 45%;
          text-align: start !important;
        }
      }
    }

    tbody {
      td {
        text-align: center !important;

        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          text-align: start !important;
        }
      }
    }
  }

  &.center {
    thead {
      th {
        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 45%;
        }
      }
    }
  }

  &.max-width {
    thead {
      tbody {
        td {
          text-align: start !important;
        }
      }
    }
  }

  &.simpleTable {
    thead {
      tr {
        th {
          background-color: #0073ba;
          color: #fff;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          td {
            background-color: #8585850a;
          }
        }
      }
    }
  }

  thead {
    tr {
      th {
        font-size: 13px !important;
        font-weight: 600 !important;
        padding: 12px 16px;
      }
    }
  }

  tbody {
    td {
      color: #757575 !important;
      vertical-align: baseline !important;
      padding: 5px 16px !important;

      &:nth-child(3) {
        width: 310px;
      }

      &:nth-child(9) {
        width: 165px;
      }

      &:last-child {
        text-align: center !important;
      }
    }
  }
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #e5e5e5;
  border-width: 1px;
}

.upload-btn {
  background-color: transparent;
  padding: 12px 0;
  text-align: center;
  border: none;
  color: #313131;

  svg {
    font-size: 22px;
  }

  &.pagination-btn {
    padding: 14px;
  }
}

.pagination-counter {
  padding: 8px 15px;
  color: #313131;
  border: 0;
  background-color: transparent;
  margin: 10px 8px;

  &.active {
    background-color: $blue;
    color: #fff;
    border-radius: 8px;
  }
}

.browse-button {
  border: 1px solid #e5e5e5;
  display: block;
  border-radius: 3px;
}

.documentList {
  margin: 0;
  padding: 0 12px;
  list-style: 0;
  margin-bottom: 20px;

  li {
    padding: 10px 0;

    &:first-child {
      h6 {
        color: #000;
        margin-bottom: 0;
      }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;

    h5 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.dropdown {
  border-left: 1px solid #e5e5e5;

  button {
    background: transparent;
    border: none;

    svg {
      color: #a2a2a2;
    }

    &:after {
      display: none;
    }

    &:hover {
      background: transparent;
      border: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    padding: 0;

    svg {
      margin-right: 5px;

      &:hover {
        color: #fff !important;
      }
    }

    a {
      padding: 10px 14px;
      border: none;

      &:hover {
        background-color: $blue;
        color: #fff;
      }
    }
  }
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: transparent;
}

.errorShow {
  color: red;
}

.blockError {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 200px;

  svg {
    color: orange;
    height: 180px;
    width: 200px;
  }
}

.react-time-picker__wrapper {
  flex-grow: 1;
  flex-shrink: 0;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius) !important;
  padding: 10px !important;
}

.react-time-picker {
  display: block !important;
}

.react-time-picker__inputGroup__input {
  &:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.react-time-picker__clock {
}

.table > :not(caption) > * > * {
  padding: 15px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
}

table tbody td {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
}

.dropdown {
  border: none;
}

.custom-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  h6 {
    flex: 0 0 50%;
    margin-bottom: 12px;

    span {
      font-weight: 400;
    }
  }
}

.text-black {
  color: #313131 !important;
}

.accordion {
  .accordion-item {
    margin-bottom: 10px;
    border: 1px solid #0073ba;

    .accordion-header {
      .accordion-button {
        background-color: #0073ba;
        color: #fff;
        font-size: 14px;

        &:focus {
          box-shadow: none;
        }

        &:after {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
            saturate(0%) hue-rotate(59deg) brightness(103%) contrast(101%);
          background-size: 15px;
        }
      }
    }

    .accordion-body {
      h6 {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 8px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.btn-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 10px;
  justify-content: flex-start;

  li {
    flex: 0 0 auto;
  }
}

@media print {
  .exclude-print {
    display: none;
  }

  [class*="MainLayout"] {
    margin-left: 0;
  }
}

.pac-container {
  z-index: 999999 !important;
  /* Adjust this value based on your modal's z-index */
}

.custom-modal-width {
  max-width: 90%;
  width: 90%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.ticket-item {
  cursor: pointer;
  transition: font-weight 0.2s ease-in-out;
  /* Optional: adds a smooth transition */
}

.ticket-item:hover {
  font-weight: bold;
}

.per-ticket-report-custom-table {
  th {
    background-color: $blue;

    span {
      color: white;
      text-transform: capitalize;
    }
  }

  th,
  td {
    margin: auto;
    text-align: center;

    strong {
      color: black;
    }
  }

  tr:nth-last-child(-n + 4) {
    td {
      border-color: black;
    }
  }

  tr:nth-last-child(4) {
    td {
      border-left-color: rgb(224, 224, 224);
      border-right-color: rgb(224, 224, 224);
    }
  }

  tr:nth-last-child(3) {
    td {
      border-bottom-width: 0;
    }
  }

  tr:nth-last-child(2) {
    td {
      border-top-width: 0;
    }
  }

  tr {
    td:nth-child(1),
    th:nth-child(1) {
      border-right-width: 0;
    }

    td:nth-child(2),
    th:nth-child(2) {
      border-right-width: 0;
      border-left-width: 0;
    }

    td:nth-child(3),
    th:nth-child(3) {
      border-right-width: 0;
      border-left-width: 0;
    }

    td:nth-child(4),
    th:nth-child(4) {
      border-left-width: 0;
    }
  }
}

.billing-report-custom-table {
  th {
    background-color: $blue;

    span {
      color: white;
      text-transform: capitalize;
    }
  }

  th,
  td {
    margin: auto;
    text-align: center;
    border-width: 0;

    strong {
      color: black;
    }
  }
}

.error-msg {
  color: $color-red;
  font-size: small;
}

/* Chrome, Safari, Edge, Opera */
.number-input-without-arrows::-webkit-outer-spin-button,
.number-input-without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input-without-arrows[type="number"] {
  -moz-appearance: textfield;
}

[class*="menu"] {
  z-index: 999 !important;
}

canvas {
  height: 100% !important;
}

.ticketNoCell {
  cursor: pointer;
  padding: 5px;
  color: white;
  text-decoration: none;
  border-radius: 3px;

  &:hover {
    opacity: 0.9;
  }
}

.successMsg {
  position: relative;
  background-color: rgba(0, 200, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.slick-next,
.slick-prev {
  &:before {
    color: #0073ba;
  }
}

.slick-prev {
  left: -10px;
}

.slick-next {
  right: -10px;
}

.slider-box {
  max-width: 735px;
  margin: auto;
  padding: 18px 0;
  position: relative;
  @include media("<widescreen") {
    .img-box {
      margin-left: 50px !important;
    }
  }

  .form-check {
    padding-left: 0 !important;

    @include media("<widescreen") {
      padding-left: 1.5em !important;
    }

    .form-check-input {
      position: absolute;
      left: -100px;
      top: 50%;
      transform: translateY(-50%);
      border-color: #86b7fe;

      @include media("<widescreen") {
        left: 20px;
      }
      @include media("<tablet-x") {
        left: 25px;
      }
    }
  }
}

.file-slider {
  text-align: center;

  .slick-track {
    margin: unset !important;
  }

  a {
    width: 32px;
    display: inline-block;
  }

  svg {
    color: #0073ba;
    font-size: 22px;
  }

  .slick-arrow {
    z-index: 99;

    &:before {
      color: #0073ba;
    }

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }
  }
}

.customDropdown {
  button {
    color: #0073ba;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);

    &:hover {
      background: #0073ba;
      color: #fff;
      border: var(--bs-border-width) solid var(--bs-border-color);
    }
  }

  .dropdown-item {
    font-size: 12px;

    &:hover {
      background: #fff;
      color: #0073ba;
    }
  }

  .btn:first-child:active,
  .btn.active,
  .btn.show {
    background: #0073ba;
    color: #fff;
  }
}
