
@import "../../Style/include-media";
.search{
    display: flex;
    align-items: flex-start;
    
    input[type="text"] {
        border-color:  #E5E5E5;
        border-right: none;
        border-radius: 5px 0px 0 5px;
        min-width: 280px;
         border-bottom: 0;
         border-bottom: 0;
        font-size: 13px;
        padding: 10px;
        @include media("<xs-phone"){
          min-width: unset;
        }
        &::placeholder{
            font-size: 13px;
            color: #AAA;
        }
        &:focus{
          border-color: #E5E5E5 !important;
        }
      }
      .searchBtn {
        border-radius: 0px 5px 5px 0;
        background: #D9EAF5;
        color: #fff;
        border: none;
        cursor: pointer;
       width: 44px;
       line-height: 38px;
       height: 43px;
      }
      
      .searchBtn i {
        font-size: 14px;
        color: #0073BA;
      }
}