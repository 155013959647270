#wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    background-image: url('../../Images/ticket.png'), url('../../Images/ticket.png');
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: 350px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }