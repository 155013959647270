#wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  main{
    min-height: 100vh;
  }
  
}

.content {
  margin-left: 260px;
  transition: all ease 0.6s;
}
