.search {
  display: flex;
  align-items: flex-start;
  position: relative;
  input[type="text"] {
    border-color: #e5e5e5;
    border-right: none;
    border-radius: 5px 0px 0 5px;
    min-width: 280px;
    font-size: 13px;
    padding: 10px;
    &::placeholder {
      font-size: 13px;
      color: #aaa;
    }
    &:focus {
      border-color: #e5e5e5 !important;
    }
  }
  .searchBtn {
    border-radius: 0px 5px 5px 0;
    background: #d9eaf5;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 44px;
    line-height: 38px;
    height: 43px;
  }

  .searchBtn i {
    font-size: 14px;
    color: #0073ba;
  }

  .result {
    position: absolute;
    top: 100%;
    background-color: #fff;
    width: 280px;
    padding: 12px;
    box-shadow: 1px 1px 1px 1px #ccc;
    list-style: none;
    border-radius: 0 0 5px 5px;
    z-index: 999;
    max-height: 200px;
    overflow: auto;
    li {
      &:hover {
        a {
          color: #0073ba;
        }
      }
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .loader {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
  }
}
