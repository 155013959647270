.flexBox {
  display: flex;
  margin-bottom: 8px;
  span {
    color: #757575;
    font-weight: 300;
  }
  p {
    color: #313131;
    margin-bottom: 0;
    font-weight: 600;
    margin-right: 12px;
    min-width:135px ;
    @media screen and (max-width: 580px) {
        min-width: 85px;
    }
  }
}

.heading {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 100%;
    border-bottom: 1px solid #e5e5e5;
  }
}
