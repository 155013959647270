.iconBtn {
  span {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background-color: #d9eaf5;
    color: #0073ba;
    padding: 3px;
    font-size: 10px;
    border-radius: 3px;
    position: relative;
    z-index: 999;
    &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 4px solid transparent;
        border-top: 12px solid #d9eaf5;
        bottom: -5px;
        left: 0;
        transform: rotate(45deg);
    }
  }
}
