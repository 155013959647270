.logo{
  position: relative;
  width: 200px;
  margin: auto;
  margin-bottom: 50px;
  visibility: visible;
    opacity: 1;
    transition: all ease 0.6s;

  a{
    display: block;
  }

  img{
    display: block;
    width: 100%;
    height: auto;
  }
}